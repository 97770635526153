import React from 'react'
import { useGratitudes } from '../FirebaseHelper'
import { Heading, Text, Flex, Card, Box } from './layout'
import Masonry from 'react-masonry-component'
import { useSpring, animated } from 'react-spring'
const GratitudeList = props => {
  const { value } = useGratitudes()
  const docs = value
    ? value.docs.map(d => {
        return { id: d.id, data: d.data() }
      })
    : []

  const AnimatedFlex = animated(Flex)
  const headingProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: !!value ? 1 : 0 },
  })

  return (
    <div>
      <AnimatedFlex
        alignItems="center"
        justifyContent="center"
        my={6}
        style={headingProps}
      >
        <Heading>Gratitudes</Heading>
      </AnimatedFlex>
      {docs && (
        <Box className="grid">
          <Masonry className="gratitude-masonry" elementType={'div'}>
            {docs.map(doc => (
              <Card
                className="gratitude"
                bg="white"
                key={doc.id}
                padding={3}
                width={200}
                mx={2}
                mt={4}
                borderRadius="10px"
                boxShadow="0 1px 2px 0 rgba(0, 0, 0, 0.16)"
              >
                <Heading variant="heading4">{doc.data.to}</Heading>
                <Text my={3}>{doc.data.for}</Text>
                <Text fontStyle="italic">{doc.data.from}</Text>
              </Card>
            ))}
          </Masonry>
        </Box>
      )}
    </div>
  )
}

export default GratitudeList
