import React, { useState, useRef } from 'react'
import { AnimatedBox, AnimatedModalButton, Box, Image } from './layout'
import { useFormMachine } from '../FormMachine'
import { useChain, useSpring, config, useTransition } from 'react-spring'
import useMeasure from '../hooks/useMeasure'

const HoverButton = props => {
  const [hovered, setHovered] = useState(false)
  const springProps = useSpring({
    from: { transform: 'scale(1)' },
    to: { transform: hovered ? 'scale(1.4)' : 'scale(1)' },
    config: { ...config.gentle, clamp: true },
  })
  return (
    <AnimatedBox
      onClick={props.onClick}
      style={{ ...springProps, cursor: 'pointer', padding: 10, margin: -10 }}
      display="inline-block"
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
    >
      <Image src={props.src} width={12} height={12} />
    </AnimatedBox>
  )
}
const ModalButton = props => {
  const { isOpen, plusIcon, closeIcon, shouldRotate } = props
  const [bind, { width, height }] = useMeasure()
  const { bottom, right } = props
  const formMachine = useFormMachine()

  const childSpringRef = useRef()
  const modalSpringRef = useRef()
  const iconSpringRef = useRef()

  const animProps = useSpring({
    from: { width: 40, height: 40, borderRadius: 20, bottom: 20, right: 20 },
    ref: modalSpringRef,
    to: {
      borderRadius: isOpen ? 0 : 40,
      width: isOpen ? width : 40,
      height: isOpen ? height : 40,
      bottom: isOpen ? 0 : bottom,
      right: isOpen ? 0 : right,
    },
    config: { tension: 210, friction: 20, clamp: true },
    immediate: props.immediate,
  })

  const transitions = useTransition(isOpen, null, {
    ref: childSpringRef,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.stiff,
  })

  const iconProps = useSpring({
    ref: iconSpringRef,
    from: { opacity: 1 },
    to: { opacity: isOpen ? 0 : 1 },
    config: config.stiff,
  })

  useChain(
    isOpen
      ? [iconSpringRef, modalSpringRef, childSpringRef]
      : [childSpringRef, modalSpringRef, iconSpringRef],
    isOpen ? [0, 0.05, 0.4] : [0, 0.05, 0.4]
  )
  return (
    <Box
      {...bind}
      style={{
        position: 'fixed',
        width: formMachine.state.matches('collapsed') ? 'auto' : '100vw',
        height: formMachine.state.matches('collapsed') ? 'auto' : '100vh',
        zIndex: 10,
      }}
    >
      <AnimatedModalButton
        style={{ ...animProps, position: 'fixed' }}
        onClick={() => formMachine.send('SHOW')}
      >
        `
        <AnimatedBox style={{ ...iconProps, cursor: 'pointer' }}>
          <Image
            src={plusIcon}
            style={{
              transform: shouldRotate ? 'rotate(45deg)' : '',
              width: 19,
              height: 19,
              position: 'absolute',
              top: 10,
              left: 10,
            }}
          />
        </AnimatedBox>
      </AnimatedModalButton>

      {transitions.map(
        ({ item, key, props: transitionItemProps }) =>
          item &&
          !formMachine.state.matches('collapsed') && (
            <Box
              position="fixed"
              key={key}
              bottom={0}
              right={0}
              display="flex"
              style={{
                alignItems: 'center',
                width: '100vw',
                height: '100vh',
              }}
            >
              <AnimatedBox
                key={key}
                width={3 / 5}
                maxWidth={800}
                mx="auto"
                position="relative"
                display="block"
                style={transitionItemProps}
              >
                <Box position="absolute" right={0} top={0}>
                  <HoverButton
                    src={closeIcon}
                    onClick={() => formMachine.send('CANCEL')}
                  />
                </Box>
                {props.children}
              </AnimatedBox>
            </Box>
          )
      )}
    </Box>
  )
}

export default ModalButton
