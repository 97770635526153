import React from 'react'
import 'firebase/auth'
import { signOut, signIn, useFirebaseUser } from '../FirebaseHelper'
import { Button, Box, Flex, Heading, AnimatedBox, Image, Text } from './layout'
import { useSpring, useTransition, animated } from 'react-spring'

function SignIn({ greeting, showSignOut }) {
  const { user } = useFirebaseUser()

  const handleSignIn = event => {
    event.preventDefault()
    signIn().catch(error => {
      console.log('error authenticating', error)
    })
  }

  const handleSignOut = event => {
    event.preventDefault()
    signOut().catch(error => {
      console.debug('sign out error', error)
    })
  }

  const springProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 800,
  })

  const transitions = useTransition(!user, null, {
    from: { opacity: 1 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  const AnimatedFlex = animated(Flex)

  return (
    <React.Fragment>
      {user && showSignOut && (
        <Flex
          onClick={handleSignOut}
          position="absolute"
          right={50}
          top={25}
          style={{ cursor: 'pointer' }}
        >
          <Image src="images/exit.svg" aria-label="exit icon" />
          <Text mx={1}>Sign out</Text>
        </Flex>
      )}
      {transitions.map(
        ({ item, key, props: transitionProps }) =>
          item && (
            <AnimatedFlex
              key={key}
              justifyContent="center"
              alignItems="center"
              position="fixed"
              bg="peach"
              style={{
                ...transitionProps,
                height: '100vh',
                width: '100%',
                top: 0,
                left: 0,
              }}
            >
              <Box style={{ textAlign: 'center' }}>
                <Heading fontSize={4}>{greeting}</Heading>
                <AnimatedBox style={springProps}>
                  <Box style={{ maxWidth: '430px' }} mx="auto">
                    <Heading variant="heading3" mt={2}>
                      Thanks for being here! Sign in with your Substantial
                      Google account to get started.
                    </Heading>
                  </Box>
                  <Box mt={4}>
                    <Button type="button" onClick={handleSignIn} width={1 / 4}>
                      SIGN IN
                    </Button>
                  </Box>
                </AnimatedBox>
              </Box>
            </AnimatedFlex>
          )
      )}
    </React.Fragment>
  )
}
export default SignIn
