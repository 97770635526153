import styled from 'styled-components'
import {
  display,
  space,
  color,
  width,
  maxWidth,
  height,
  flex,
  order,
  alignSelf,
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
  fontSize,
  fontFamily,
  fontWeight,
  fontStyle,
  textAlign,
  lineHeight,
  letterSpacing,
  borders,
  borderColor,
  borderRadius,
  buttonStyle,
  boxShadow,
  backgroundImage,
  backgroundSize,
  backgroundPosition,
  backgroundRepeat,
  opacity,
  variant,
  position,
  top,
  bottom,
  right,
} from 'styled-system'

import { animated } from 'react-spring'

const themed = key => props => props.theme[key]

export const Box = styled('div')(
  {
    boxSizing: 'border-box',
  },
  display,
  space,
  width,
  maxWidth,
  fontSize,
  color,
  flex,
  order,
  alignSelf,
  position,
  top,
  right,
  bottom,
  themed('Box')
)

Box.propTypes = {
  ...space.propTypes,
  ...width.propTypes,
  ...fontSize.propTypes,
  ...color.propTypes,
  ...flex.propTypes,
  ...order.propTypes,
  ...alignSelf.propTypes,
}

const ModalButton = styled(Box)(themed('ModalButton'))
export const AnimatedBox = animated(Box)
export const AnimatedModalButton = animated(ModalButton)

export const Flex = styled(Box)(
  {
    display: 'flex',
  },
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
  themed('Flex')
)

const textModes = variant({ key: 'textModes' })
export const Text = styled(Box)(
  fontFamily,
  fontStyle,
  fontWeight,
  textAlign,
  lineHeight,
  letterSpacing,
  themed('Text', textModes)
)

Text.propTypes = {
  ...fontFamily.propTypes,
  ...fontWeight.propTypes,
  ...textAlign.propTypes,
  ...lineHeight.propTypes,
  ...letterSpacing.propTypes,
}

const headings = variant({ key: 'headings' })
export const Heading = styled(Text)(themed('Heading'), headings)
Heading.defaultProps = {
  variant: 'heading1',
}

const buttons = variant({ key: 'buttons' })

export const Button = styled(Box)(
  {
    appearance: 'none',
    display: 'inline-block',
    textAlign: 'center',
    lineHeight: 'inherit',
    textDecoration: 'none',
    textTransform: 'uppercase',
  },
  fontWeight,
  borders,
  borderColor,
  borderRadius,
  buttonStyle,
  themed('Button'),
  buttons,
  position,
  right,
  top
)

Button.propTypes = {
  ...fontFamily.propTypes,
  ...fontWeight.propTypes,
  ...borders.propTypes,
  ...borderColor.propTypes,
  ...borderRadius.propTypes,
  ...buttonStyle.propTypes,
}

Button.defaultProps = {
  as: 'button',
  variant: 'primary',
}

export const Image = styled(Box)(
  {
    maxWidth: '100%',
    height: 'auto',
  },
  height,
  borderRadius,
  themed('Image')
)

Image.propTypes = {
  ...height.propTypes,
  ...borderRadius.propTypes,
}

Image.defaultProps = {
  as: 'img',
  m: 0,
}

const cards = variant({ key: 'cards' })
export const Card = styled(Box)(
  borders,
  borderColor,
  borderRadius,
  boxShadow,
  backgroundImage,
  backgroundSize,
  backgroundPosition,
  backgroundRepeat,
  opacity,
  cards,
  themed('Card')
)

Card.propTypes = {
  ...borders.propTypes,
  ...borderColor.propTypes,
  ...borderRadius.propTypes,
  ...boxShadow.propTypes,
  ...backgroundImage.propTypes,
  ...backgroundSize.propTypes,
  ...backgroundPosition.propTypes,
  ...backgroundRepeat.propTypes,
  ...opacity.propTypes,
  ...cards.propTypes,
}

const inputModes = variant({ key: 'inputModes' })

export const Textarea = styled('textarea')(
  space,
  borders,
  color,
  fontFamily,
  fontWeight,
  fontSize,
  textAlign,
  lineHeight,
  letterSpacing,
  width,
  height,
  themed('Textarea', inputModes)
)

export const Input = styled('input')(
  space,
  color,
  flex,
  fontFamily,
  fontWeight,
  fontSize,
  textAlign,
  lineHeight,
  letterSpacing,
  width,
  themed('Input')
)

export const Label = styled('label')(
  space,
  color,
  fontFamily,
  fontWeight,
  fontSize,
  textAlign,
  lineHeight,
  letterSpacing,
  width,
  themed('Label')
)
