const fontSizes = [11, 14, 18, 24, 40]
const space = [0, 8, 16, 20, 30, 40, 60, 120]
const colors = {
  black: '#000e1a',
  white: '#fff',
  red: '#e76873',
  gray: '#647380',
  peach: '#ffe9e1',
}

export default {
  colors,
  fontSizes,
  space,

  buttons: {
    primary: {
      color: 'white',
      backgroundColor: colors.red,
      '&:hover': {
        backgroundColor: '#e0535f',
      },
      '&:disabled': {
        backgroundColor: 'rgb(0,0,0,0.0)',
        // border: '2px solid #e76873',
        borderRadius: '8px',
        color: colors.red,
        boxShadow: 'inset 0 0 0 2px #e76873',
      },
    },
    secondary: {
      color: 'white',
      backgroundColor: '#647380',
      '&:hover': {
        backgroundColor: '#56636d',
      },
      '&:disabled': {
        backgroundColor: '#848e96',
      },
    },
    modalPrimary: {
      color: colors.red,
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: '#FBFBFB',
      },
      '&:disabled': {
        backgroundColor: 'rgb(255,255,255,0.8)',
      },
    },
    noFrills: {
      backgroundColor: 'rgb(0,0,0,0.0)',
      padding: 0,
      margin: 0,
    },
  },
  headings: {
    heading1: {
      fontWeight: 100,
      fontSize: fontSizes[4],
    },
    heading2: {
      fontWeight: 100,
      fontSize: fontSizes[3],
    },
    heading3Bold: {
      fontWeight: 100,
      fontSize: fontSizes[2],
    },
    heading3: {
      fontWeight: 100,
      fontSize: fontSizes[2],
    },
    heading4: {
      fontWeight: 100,
      fontSize: fontSizes[1],
    },
  },

  Button: {
    fontSize: '10px',
    fontWeight: 500,
    fontFamily: 'titling-gothic-fb-wide',
    minHeight: '32px',
    margin: 0,
    paddingLeft: space[5],
    paddingRight: space[5],
    paddingTop: space[1],
    paddingBottom: space[1],
    border: space[0],
    borderRadius: '8px',
    cursor: 'pointer',
    '&:disabled': {
      cursor: 'default',
    },
  },

  Heading: {
    as: 'h2',
    fontFamily: 'titling-gothic-fb',
  },

  Text: {
    fontFamily: 'aktiv-grotesk',
    fontSize: fontSizes[0],
    lineHeight: 1.45,
  },

  Input: {
    backgroundColor: 'rgba(0,0,0,0)',
    border: 'none',
    borderBottom: '2px solid rgba(255,255,255,0.4)',
    color: colors.white,
    fontSize: fontSizes[2],
    fontFamily: 'titling-gothic-fb',
    fontWeight: 100,
    outline: 'none',
    '&::placeholder': {
      color: 'rgba(255,255,255,0.4)',
    },
    '&:focus': {
      outline: 'rgba(255,255,255,0.8) solid 2px',
      outlineOffset: '4px',
    },
  },

  Label: {
    fontSize: fontSizes[2],
    fontFamily: 'titling-gothic-fb',
    color: colors.white,
    fontWeight: 100,
  },

  Textarea: {
    backgroundColor: 'rgba(0,0,0,0)',
    border: 'none',
    borderBottom: '2px solid rgba(255,255,255,0.4)',
    color: colors.white,
    fontSize: fontSizes[2],
    fontFamily: 'titling-gothic-fb',
    fontWeight: 100,
    resize: 'none',
    '&::placeholder': {
      color: 'rgba(255,255,255,0.4)',
    },
    '&:focus': {
      outline: 'rgba(255,255,255,0.8) solid 2px',
      outlineOffset: '4px',
    },
  },

  ModalButton: {
    backgroundColor: colors.red,
  },
}
