import * as firebase from 'firebase/app'
import 'firebase/firestore'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useCollection } from 'react-firebase-hooks/firestore'

const previewConfig = {
  apiKey: 'AIzaSyC5C4Rz_0XbwxVhwlF71VZ43iZ0iQnhKj0',
  authDomain: 'grattitude-b6764.firebaseapp.com',
  databaseURL: 'https://grattitude-b6764.firebaseio.com',
  projectId: 'grattitude-b6764',
  storageBucket: 'grattitude-b6764.appspot.com',
  messagingSenderId: '859273135462',
}

const productionConfig = {
  apiKey: 'AIzaSyAoQlfsdI76jVZ1_spu8fxv06MCN1mny2M',
  authDomain: 'substantial-gratitudes-prod.firebaseapp.com',
  databaseURL: 'https://substantial-gratitudes-prod.firebaseio.com',
  projectId: 'substantial-gratitudes-prod',
  storageBucket: 'substantial-gratitudes-prod.appspot.com',
  messagingSenderId: '932546909329',
}
const config =
  process.env.REACT_APP_ACTIVE_ENV === 'production'
    ? productionConfig
    : previewConfig

const initializeFirebase = () => {
  firebase.initializeApp(config)
}

const useFirebaseUser = () => {
  return useAuthState(firebase.auth())
}

const useGratitudes = () => {
  return useCollection(gratitudes().orderBy('date', 'desc'))
}

const useOptimisms = () => {
  return useCollection(optimisms().orderBy('date', 'desc'))
}

const addGratitude = doc => {
  return gratitudes().add(doc)
}

const addOptimism = doc => {
  return optimisms().add(doc)
}

const signIn = async () => {
  return firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider())
}

const signOut = async () => {
  return firebase.auth().signOut()
}

const gratitudes = () => firebase.firestore().collection('gratitudes')
const optimisms = () => firebase.firestore().collection('optimisms')

export {
  addGratitude,
  addOptimism,
  signOut,
  signIn,
  initializeFirebase,
  useFirebaseUser,
  useGratitudes,
  useOptimisms,
  gratitudes,
}
