import React from 'react'
import SignIn from '../components/SignIn'
import GratitudeList from '../components/GratitudeList'
import NewGratitude from '../components/NewGratitude'
import ContinueOrDone from '../components/ContinueOrDone'
import ModalButton from '../components/ModalButton'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import { FormMachineConfig, FormMachineContext } from '../FormMachine'
import { useMachine } from 'use-machine'
import { Box, Flex } from '../components/layout'
import theme from '../theme'

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${props => props.theme.colors.peach}
    color: ${props => props.theme.colors.gray}
  }
`

const Gratitudes = ({ user }) => {
  const formMachine = useMachine(FormMachineConfig)

  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <GlobalStyle />
        <Box px={5} py={4}>
          <Flex px={3}>
            <FormMachineContext.Provider value={formMachine}>
              <SignIn
                greeting="Let's share some gratitude."
                showSignOut={true}
              />
              {user && (
                <>
                  <Box mx={3}>
                    <ModalButton
                      bottom={20}
                      right={20}
                      isOpen={!formMachine.state.matches('collapsed')}
                      immediate={false}
                      plusIcon="/images/x.svg"
                      closeIcon="/images/x.svg"
                      shouldRotate={true}
                    >
                      <NewGratitude />
                    </ModalButton>
                  </Box>
                  {formMachine.state.matches('continueOrDone') && (
                    <ContinueOrDone />
                  )}
                </>
              )}
            </FormMachineContext.Provider>
          </Flex>
          {user && <GratitudeList />}
        </Box>
      </React.Fragment>
    </ThemeProvider>
  )
}

export default Gratitudes
