import React, { useContext } from 'react'

export const FormMachineConfig = {
  initial: 'collapsed',
  states: {
    collapsed: {
      on: {
        SHOW: 'editing',
      },
    },
    editing: {
      on: {
        SUBMIT: 'submitting',
        CANCEL: 'collapsed',
      },
    },
    submitting: {
      on: {
        SUCCESS: 'continueOrDone',
        ERROR: 'editing',
      },
    },
    continueOrDone: {
      on: {
        CONTINUE: 'editing',
        DONE: 'collapsed',
      },
    },
  },
}

export const OptimismFlow = {
  initial: 'collapsed',
  states: {
    collapsed: {
      on: {
        SHOW: 'editing',
      },
    },
    editing: {
      on: {
        SUBMIT: 'submitting',
        CANCEL: 'collapsed',
      },
    },
    submitting: {
      on: {
        SUCCESS: 'doneSubmitting',
        ERROR: 'editing',
      },
    },
    doneSubmitting: {
      on: {
        TIMER: 'collapsed',
      },
    },
  },
}
export const FormMachineContext = React.createContext()

export const useFormMachine = () => {
  return useContext(FormMachineContext)
}
