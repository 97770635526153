import React, { useState } from 'react'
import { addGratitude } from '../FirebaseHelper'
import { useFormMachine } from '../FormMachine'

import {
  Textarea,
  Box,
  Input,
  Image,
  Button,
  Heading,
  Label,
  Flex,
  Text,
} from './layout'

import SpringBox from './SpringBox'

const NewGratitude = props => {
  let [to, setTo] = useState('')
  let [from, setFrom] = useState('')
  let [what, setWhat] = useState('')
  let [hasEdited, setHasEdited] = useState(false)

  let updateToState = event => setTo(event.target.value)
  let updateFromState = event => setFrom(event.target.value)
  let updateWhatState = event => setWhat(event.target.value)
  let handleBlur = event => setHasEdited(true)
  const formMachine = useFormMachine()

  const validate = () => {
    let toErrors = []
    if (to.trim() === '' || !to) {
      toErrors.push('required')
    }

    let whatErrors = []
    if (what.trim() === '' || !what) {
      whatErrors.push('required')
    }

    return {
      whatErrors,
      toErrors,
    }
  }

  const hasErrors = errors => {
    return [...errors.whatErrors, ...errors.toErrors].length > 0
  }

  const reset = () => {
    const values = { target: { value: '' } }
    updateFromState(values)
    updateWhatState(values)
    updateToState(values)
  }

  const sendGratitude = () => {
    formMachine.send('SUBMIT')
    const gratitude = {
      from,
      to,
      for: what,
      date: new Date(),
    }
    addGratitude(gratitude).then(() => {
      setTimeout(() => reset(), 1000)
      formMachine.send('SUCCESS')
    })
  }

  const errorText =
    hasEdited && hasErrors(validate()) ? (
      'Recipient and gratitude fields are required'
    ) : (
      <React.Fragment>&nbsp;</React.Fragment>
    )
  return (
    <Box>
      <Heading color="white">Add Gratitude</Heading>
      <Flex my={4} width={1 / 1}>
        <Label htmlFor="to" lineHeight="45px">
          Thanks to
        </Label>
        <Input
          flex="1 1 auto"
          ml={2}
          type="text"
          name="to"
          id="to"
          placeholder="recipient"
          value={to}
          onChange={updateToState}
          onBlur={handleBlur}
          autoComplete="off"
        />
      </Flex>
      <Flex my={4}>
        <Label htmlFor="from" lineHeight="45px">
          From
        </Label>
        <Input
          ml={2}
          type="text"
          name="from"
          id="from"
          value={from}
          placeholder="your name"
          flex="1 1 auto"
          onChange={updateFromState}
          onBlur={handleBlur}
          autoComplete="off"
        />
      </Flex>
      <Box my={4}>
        <Label htmlFor="what">Thanks for...</Label>
        <Textarea
          name="what"
          id="what"
          value={what}
          height={122}
          placeholder="reason you're grateful"
          width={1}
          mt={1}
          onChange={updateWhatState}
          onBlur={handleBlur}
          autoComplete="off"
        />
      </Box>
      <Box mt={4}>
        <Button
          onClick={sendGratitude}
          variant="modalPrimary"
          disabled={
            formMachine.state.matches('continueOrDone') ||
            formMachine.state.matches('submitting') ||
            hasErrors(validate())
          }
          width={171}
        >
          {formMachine.state.matches('continueOrDone') && (
            <Box role="status" title="done">
              <SpringBox>
                <Image src="images/check.svg" />
              </SpringBox>
            </Box>
          )}
          {formMachine.state.matches('editing') && 'Submit'}
        </Button>

        <Text fontSize={1} color="white" mt={3}>
          {errorText}
        </Text>
      </Box>
    </Box>
  )
}

export default NewGratitude
