import React, { useState } from 'react'
import { AnimatedBox, Box } from './layout'
import { useSpring } from 'react-spring'

const SpringBox = props => {
  const [state, toggle] = useState(true)
  const { x } = useSpring({
    from: { x: 0.5 },
    x: state ? 1 : 0.5,
    config: { mass: 0.4, tension: 150, friction: 4 },
  })

  return (
    <Box onClick={() => toggle(!state)}>
      <AnimatedBox
        display="inline-block"
        style={{
          transFormOrigin: 'center',
          transform: x.interpolate(x => `scale(${x})`),
        }}
      >
        {props.children}
      </AnimatedBox>
    </Box>
  )
}

export default SpringBox
