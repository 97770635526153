import React from 'react'
import { AnimatedBox, Box, Image, Heading, Flex } from './layout'
import { useSpring } from 'react-spring'
import { useFormMachine } from '../FormMachine'

const continueDelay = 600
const ContinueOrDone = props => {
  const formMachine = useFormMachine()
  const boxProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: continueDelay,
  })

  const continueProps = useSpring({
    from: { transform: 'translate(0,100vh)' },
    to: { transform: 'translate(0,0)' },
    delay: continueDelay + 600,
    config: { tension: 191.47, friction: 21.17 },
  })

  const doneProps = useSpring({
    from: { transform: 'translate(0,100vh)' },
    to: { transform: 'translate(0,0)' },
    delay: continueDelay + 750,
    config: { tension: 191.47, friction: 22 },
  })
  return (
    <AnimatedBox
      bg="peach"
      display="flex"
      style={{
        ...boxProps,
        alignItems: 'center',
        top: 0,
        left: 0,
        zIndex: 100,
        position: 'fixed',
        width: '100vw',
        height: '100vh',
      }}
    >
      <Box width={3 / 8} mx="auto" position="relative" display="block">
        <AnimatedBox
          py={3}
          style={{ ...continueProps, cursor: 'pointer' }}
          onClick={() => {
            formMachine.send('CONTINUE')
          }}
        >
          <Flex>
            <Image src="images/add-button.svg" />
            <Box ml={3}>
              <Heading variant="heading2">
                Let's add some more gratitudes
              </Heading>
            </Box>
          </Flex>
        </AnimatedBox>
        <AnimatedBox
          py={3}
          style={{ ...doneProps, cursor: 'pointer' }}
          onClick={() => {
            formMachine.send('DONE')
          }}
        >
          <Flex>
            <Image src="images/check-button.svg" />
            <Box ml={3}>
              <Heading variant="heading2">I'm done for now</Heading>
            </Box>
          </Flex>
        </AnimatedBox>
      </Box>
    </AnimatedBox>
  )
}

export default ContinueOrDone
