import React from 'react'
import SignIn from '../components/SignIn'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import theme from '../optimisms/theme'
import { Box, Flex } from '../components/layout'
import OptimismList from '../components/optimism/OptimismList'
import { OptimismFlow, FormMachineContext } from '../FormMachine'
import NewOptimism from '../components/optimism/NewOptimism'
import ContinueOrDone from '../components/ContinueOrDone'
import ModalButton from '../components/ModalButton'
import { useMachine } from 'use-machine'

const GlobalStyle = createGlobalStyle`
  body {
    background-image: linear-gradient(241deg, #ee2747 0%, #ff6e4f 100%);
    background-repeat: no-repeat;
    background-attachment: fixed;
    // background-color: ${props => props.theme.colors.canary}
    color: ${props => props.theme.colors.black}
  
`

const Optimisms = ({ user }) => {
  const formMachine = useMachine(OptimismFlow)

  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        <Box px={5} py={4}>
          <Flex px={3}>
            <FormMachineContext.Provider value={formMachine}>
              <SignIn greeting="The Future is wide open." />
              {user && (
                <>
                  <Box mx={3}>
                    <ModalButton
                      bottom={20}
                      right={20}
                      isOpen={!formMachine.state.matches('collapsed')}
                      immediate={false}
                      plusIcon="/images/optimisms/plus.svg"
                      closeIcon="/images/optimisms/x.svg"
                    >
                      <NewOptimism />
                    </ModalButton>
                  </Box>
                  {formMachine.state.matches('continueOrDone') && (
                    <ContinueOrDone />
                  )}
                </>
              )}
            </FormMachineContext.Provider>
          </Flex>
          {user && <OptimismList />}
        </Box>
      </>
    </ThemeProvider>
  )
}
export default Optimisms
