import React from 'react'
import Gratitudes from './routes/Gratitudes'
import Optimisms from './routes/Optimisms'
import { useFirebaseUser, initializeFirebase } from './FirebaseHelper'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

initializeFirebase()

const App = props => {
  const { user } = useFirebaseUser()

  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route path="/optimisms">
            <Optimisms user={user} />
          </Route>
          <Route path="/">
            <Gratitudes user={user} />
          </Route>
        </Switch>
      </Router>
    </React.Fragment>
  )
}

export default App
