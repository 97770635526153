import React, { useState } from 'react'
import { addOptimism } from '../../FirebaseHelper'
import { useFormMachine } from '../../FormMachine'

import { Textarea, Box, Image, Button, Heading, Text } from '../layout'

import SpringBox from '../SpringBox'

const NewOptimism = props => {
  let [what, setWhat] = useState('')
  let [hasEdited, setHasEdited] = useState(false)

  let updateWhatState = event => setWhat(event.target.value)
  let handleBlur = event => setHasEdited(true)
  const formMachine = useFormMachine()

  const validate = () => {
    let whatErrors = []
    if (what.trim() === '' || !what) {
      whatErrors.push('required')
    }

    return {
      whatErrors,
    }
  }

  const hasErrors = errors => {
    return [...errors.whatErrors].length > 0
  }

  const sendOptimism = () => {
    formMachine.send('SUBMIT')
    const optimism = {
      what,
      date: new Date(),
    }
    addOptimism(optimism).then(() => {
      formMachine.send('SUCCESS')
      setTimeout(() => {
        formMachine.send('TIMER')
      }, 900)
    })
  }

  const errorText =
    hasEdited && hasErrors(validate()) ? (
      ''
    ) : (
      <React.Fragment>&nbsp;</React.Fragment>
    )
  return (
    <Box>
      <Heading variant="heading2">What are you optimistic about?</Heading>
      <Box my={4}>
        <Textarea
          name="what"
          id="what"
          variant="modal"
          value={what}
          height={122}
          placeholder="Tap here to respond"
          width={1}
          mt={1}
          onChange={updateWhatState}
          onBlur={handleBlur}
          autoComplete="off"
        />
      </Box>
      <Box mt={4}>
        <Button
          onClick={sendOptimism}
          variant="modalPrimary"
          disabled={
            formMachine.state.matches('submitting') ||
            formMachine.state.matches('doneSubmitting') ||
            hasErrors(validate())
          }
          width={171}
        >
          {formMachine.state.matches('doneSubmitting') && (
            <Box role="status" title="done">
              <SpringBox>
                <Image
                  src="images/optimisms/check.svg"
                  style={{
                    height: 11,
                  }}
                />
              </SpringBox>
            </Box>
          )}
          {formMachine.state.matches('editing') && 'Submit'}
        </Button>

        <Text fontSize={1} variant="modal" mt={3}>
          {errorText}
        </Text>
      </Box>
    </Box>
  )
}

export default NewOptimism
